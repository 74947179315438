.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.centered .iconWrapper {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

$innerSize: 4.125rem;
$outerSize: calc($innerSize - 0.125rem);
$thickness: 3px;

.iconWrapper {
    position: relative;
    line-height: 1;
    overflow: hidden;

    svg {
        width: $innerSize;
        height: $innerSize;
    }
    .rotation {
        width: calc(#{$outerSize} + #{$thickness});
        height: calc(#{$outerSize} + #{$thickness});
        border: $thickness transparent solid;
        border-top-color: #FEC30C;
        border-radius: 50%;
        animation-iteration-count: infinite;
        animation-name: rotation;
        animation-duration: 1500ms;
        position: absolute;
        animation-timing-function: linear;
    }

}

// (360-x-20)/25 = x/45
// it rotates for x deg with 'fast' speed, then it rotates for 20 deg with 'slow speed' and finally it rotates up to full circle with the same 'fast' speed
// Where 45 is (100% - first_breakpoint) and 25 is (100% - last_breakpoint); 20 - delta deg for slow part; result (x) is delta for movement before slow part. 0deg everywhere in the first place is initial angle

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    55% {
        transform: rotate(calc(0deg + 218deg));
    }

    75% {
        transform: rotate(calc(0deg + 218deg + 20deg));
    }

    100% {
        transform: rotate(calc(0deg + 360deg));
    }
}