.triggerBox {
    display: flex;
    align-items: center;
    column-gap: 0.6875rem;
}

.triggerBox.dark .hint {
    color: #666666;
}

.triggerBox.dark .icon {
    fill: #999999;
}

.triggerBox.popupOpen .hint {
    color: #666666 !important;
}

.triggerBox.popupOpen .icon {
    fill: #666666 !important;
}

.triggerBox.popupOpen.dark .hint {
    color: #303030 !important;
}

.triggerBox.popupOpen.dark .icon {
    fill: #303030 !important;
}

.icon {
    fill: #A8ADB0;
}

.hint {
    color: #999999;
}